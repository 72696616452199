import classNames from 'classnames';

interface BadgeProps {
	tag?: 'div' | 'label' | 'li';
	isRounded?: boolean;
	className?: string;
	htmlFor?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	isElsewherePresents?: boolean;
}

export const Badge = ({
	tag = 'div',
	isRounded = false,
	className,
	htmlFor,
	children,
	style,
	isElsewherePresents = false,
}: BadgeProps) => {
	const Tag = tag as keyof JSX.IntrinsicElements;

	return (
		<Tag
			className={classNames(className, 'badge', {
				'badge--rounded': isRounded,
				'badge--elsewhere-presents': isElsewherePresents,
			})}
			htmlFor={htmlFor}
			style={style}
		>
			{children}
		</Tag>
	);
};
