import { getSrc } from '@common/utils/diceImage';
import { formatPriceWithoutCurrency } from '@common/utils/price';
import {
	createEventSlug,
	getEventMetaDescription,
	getIsEventSoldOut,
	getIsLiveStreamEvent,
	getRepresentativeTicketPrice,
	getTicketUrl,
	sortDetailedArtists,
} from '@common/utils/programme';
import { PRODUCTION_WEBSITE_URL } from '@data/constants';
import { DiceEvent } from '@interfaces';

interface ListingAdditionalMicrodataProps {
	event: DiceEvent;
	isWithDescription?: boolean;
	isWithArtistList?: boolean;
	isWithImage?: boolean;
	isWithOffer?: boolean;
	isWithUrl?: boolean;
}

export const ListingAdditionalMicrodata = ({
	event,
	isWithDescription = false,
	isWithArtistList = false,
	isWithImage = false,
	isWithOffer = false,
	isWithUrl = false,
}: ListingAdditionalMicrodataProps) => {
	const { address, eventImages, dateEnd, status, currency, saleStartDate } =
		event;

	const isLiveStreamEvent = getIsLiveStreamEvent(event);
	const isEventCancelled = status === 'cancelled';
	const isEventSoldOut = getIsEventSoldOut(event);
	const detailedArtists = sortDetailedArtists(event.detailedArtists) || [];
	const eventDescription = getEventMetaDescription(event);
	const ticketPrice = getRepresentativeTicketPrice(event);
	const ticketUrl = getTicketUrl(event);

	return (
		<>
			<div
				itemProp="location"
				itemScope
				itemType="https://schema.org/MusicVenue"
			>
				<meta content="Elsewhere" itemProp="name" />
				{address ? <meta content={address} itemProp="address" /> : null}
			</div>

			<div
				itemProp="organizer"
				itemScope
				itemType="https://schema.org/Organization"
			>
				<meta content="Elsewhere" itemProp="name" />
				<meta content={PRODUCTION_WEBSITE_URL} itemProp="url" />
			</div>

			<meta content={dateEnd} itemProp="endDate" />

			<meta
				content={
					isEventCancelled
						? 'https://schema.org/EventCancelled'
						: 'https://schema.org/EventScheduled'
				}
				itemProp="eventStatus"
			/>

			<meta
				content={
					isLiveStreamEvent
						? 'https://schema.org/MixedEventAttendanceMode'
						: 'https://schema.org/OfflineEventAttendanceMode'
				}
				itemProp="eventAttendanceMode"
			/>

			{isWithDescription ? (
				<meta content={eventDescription} itemProp="description" />
			) : (
				''
			)}

			{isWithArtistList ? (
				<>
					{detailedArtists.map(({ id, name }) => {
						return (
							<div
								itemProp="performer"
								itemScope
								itemType="https://schema.org/MusicGroup"
								key={id}
							>
								<meta content={name} itemProp="name" />
							</div>
						);
					})}
				</>
			) : null}

			{isWithImage && eventImages ? (
				<meta
					content={getSrc(eventImages.square, { w: 400 })}
					itemProp="image"
				/>
			) : null}

			{isWithOffer ? (
				<div itemProp="offers" itemScope itemType="https://schema.org/Offer">
					{ticketUrl ? <link href={ticketUrl} itemProp="url" /> : null}
					<link
						href={`https://schema.org/${
							isEventSoldOut ? `SoldOut` : `InStock`
						}`}
						itemProp="availability"
					/>
					{ticketPrice ? (
						<meta
							content={formatPriceWithoutCurrency({ cents: ticketPrice })}
							itemProp="price"
						/>
					) : null}
					<meta content={currency} itemProp="priceCurrency" />
					<meta content={saleStartDate} itemProp="validFrom" />
				</div>
			) : null}

			{isWithUrl ? (
				<link href={`/events/${createEventSlug(event)}`} itemProp="url" />
			) : null}
		</>
	);
};
