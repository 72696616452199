import classNames from 'classnames';

import { Badge } from '@components/badge/Badge';
import { TextMarquee } from '@components/textMarquee/TextMarquee';

import { getEventType, sanitizeVenueName } from '@common/utils/programme';
import { DiceEvent } from '@interfaces';

import styles from './listing-badge-list.module.scss';

interface ListingBadgeListProps {
	event: DiceEvent;
	className?: string;
	isElsewherePresents?: boolean;
	isVenueNameInMarquee?: boolean;
}

export const ListingBadgeList = ({
	event,
	className,
	isElsewherePresents = false,
	isVenueNameInMarquee = false,
}: ListingBadgeListProps) => {
	const { typeTags, venues } = event;
	const eventType = getEventType(typeTags);

	const renderMarquee = (venueName: string) => {
		const text = isVenueNameInMarquee ? venueName : 'Elsewhere presents';
		return <TextMarquee isElsewherePresentsVenue text={`↗ ${text}`} />;
	};

	return (
		<ul
			className={classNames(
				className,
				styles['listing-badge-list'],
				'list-unstyled',
				styles[`listing-badge-list--is-${eventType.toLowerCase()}`],
			)}
		>
			{event.soldOut ? (
				<Badge className={styles['listing-badge-list__sold-out']} tag="li">
					SOLD OUT
				</Badge>
			) : null}
			{eventType.length ? (
				<Badge
					className={styles['listing-badge-list__type']}
					isRounded
					tag="li"
				>
					{eventType}
				</Badge>
			) : null}
			{venues.map(({ id, name }) => (
				<Badge
					className={classNames({
						[styles['listing-badge-list__space']]: !isElsewherePresents,
					})}
					isElsewherePresents={isElsewherePresents}
					key={id}
					tag="li"
				>
					{isElsewherePresents ? renderMarquee(name) : sanitizeVenueName(name)}
				</Badge>
			))}
		</ul>
	);
};
