type FitParam = 'crop' | 'none';

interface SrcParams {
	w: number;
	auto?: string;
	h?: number;
	q?: number;
	fit?: FitParam;
}

interface SrcSetParams {
	q?: number;
	fit?: FitParam;
}

const defaults = {
	imageQuality: 50,
	widths: [400, 800, 1200, 1600, 2000],
	fit: 'crop',
};

const getParamString = (params: SrcParams): string => {
	const stringifiedParams = Object.entries(params).reduce(
		(obj, [key, value]) => Object.assign(obj, { [key]: value.toString() }),
		{},
	);
	const searchParams = new URLSearchParams(stringifiedParams);
	return searchParams.toString();
};

const stripQueryString = (url: string) => {
	const urlObject = new URL(url);
	return urlObject.origin + urlObject.pathname;
};

export const getSrc = (url: string, params: SrcParams): string => {
	const cleanUrl = stripQueryString(url);

	params.q = params.q || defaults.imageQuality;
	params.auto = params.auto || 'compress,format';

	// Ensure a square crop
	params.h = params.h || params.w;
	params.fit = params.fit || (defaults.fit as FitParam);

	return `${cleanUrl}?${getParamString(params)}`;
};

export const getSrcSet = (
	url: string,
	widths: number[] = defaults.widths,
	params?: SrcSetParams,
): string => {
	return widths
		.map((width) => `${getSrc(url, { w: width, ...params })} ${width}w`)
		.join(', ');
};
