import Head from 'next/head';

import { SeoMetaTag } from '@interfaces';

interface SeoProps {
	metaTagData: SeoMetaTag[];
}

const getMetaTagKey = ({ tag, attributes }: Omit<SeoMetaTag, 'content'>) => {
	const propertyNames = ['property', 'name', 'rel', 'sizes'];

	return propertyNames.reduce((key: string, propertyName: string) => {
		if (attributes && propertyName in attributes) {
			return `${key}-${attributes[propertyName]}`;
		}

		return key;
	}, tag);
};

const renderMetaTags = (data: SeoMetaTag[]): JSX.Element[] => {
	return data.map(({ __typename, tag, attributes, content }) => {
		const Tag = tag;
		const key = getMetaTagKey({ __typename, tag, attributes });

		return (
			<Tag key={key} {...attributes}>
				{content}
			</Tag>
		);
	});
};

export const Seo = ({ metaTagData }: SeoProps) => {
	const metaTags = renderMetaTags(metaTagData);

	return <Head>{metaTags}</Head>;
};
