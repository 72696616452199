import Link from 'next/link';
import classNames from 'classnames';

import { formatDateHuman } from '@common/utils/date';
import { getSrc, getSrcSet } from '@common/utils/diceImage';
import { createEventSlug } from '@common/utils/programme';
import { DiceLinkoutOriginPageTypes } from '@common/utils/tracking';
import {
	DiceEvent,
	ListingPreviewHeadingTag,
	ProgrammeLabels,
} from '@interfaces';

import styles from './listing-thumbnail.module.scss';
import { ListingAdditionalMicrodata } from './ListingAdditionalMicrodata';
import { ListingAnchors } from './ListingAnchors';
import { ListingBadgeList } from './ListingBadgeList';

interface ListingThumbnailProps {
	event: DiceEvent;
	sizes: string;
	isBadgeListHidden?: boolean;
	className?: string;
	headingTag?: ListingPreviewHeadingTag;
	isElsewherePresents?: boolean;
	programmeLabels?: ProgrammeLabels;
	showTicketAnchors?: boolean;
	serverNow?: number;
	diceAccessCode?: string;
}

export const ListingThumbnail = ({
	event,
	sizes,
	isBadgeListHidden = false,
	className,
	isElsewherePresents = false,
	headingTag = 'h2',
	programmeLabels,
	showTicketAnchors = false,
	serverNow,
	diceAccessCode,
}: ListingThumbnailProps) => {
	const { date, eventImages, images, name: title } = event;

	const isPast = !!serverNow && serverNow > new Date(event.dateEnd).getTime();

	const HeadingTag = headingTag as keyof JSX.IntrinsicElements;

	let imgUrl = images[0];

	if (eventImages) {
		imgUrl = eventImages.square;
	}

	return (
		<article
			className={classNames(className, styles['listing-thumbnail'])}
			itemScope
			itemType="https://schema.org/MusicEvent"
		>
			<ListingAdditionalMicrodata
				event={event}
				isWithArtistList
				isWithDescription
				isWithOffer
			/>
			{imgUrl ? (
				<img
					alt=""
					height={1080}
					itemProp="image"
					loading="lazy"
					sizes={sizes}
					src={getSrc(imgUrl, { w: 400 })}
					srcSet={getSrcSet(imgUrl, [400, 800])}
					width={1080}
				/>
			) : null}
			<header className={styles['listing-thumbnail__header']}>
				<time className="font-t4" dateTime={date} itemProp="startDate">
					{formatDateHuman(date)}
				</time>
				<HeadingTag className="font-t3-title">
					<Link
						className={classNames(
							styles['listing-thumbnail__anchor'],
							'breakout',
						)}
						href={`/${
							isElsewherePresents ? 'presents' : 'events'
						}/${createEventSlug(event)}`}
						itemProp="url"
					>
						<span itemProp="name">{title}</span>
					</Link>
				</HeadingTag>
				{!isBadgeListHidden ? (
					<ListingBadgeList
						className={styles['listing-thumbnail__badge-list']}
						event={event}
						isElsewherePresents={isElsewherePresents}
					/>
				) : null}
			</header>
			{showTicketAnchors && programmeLabels ? (
				<div className={styles['listing-thumbnail__anchors']}>
					<ListingAnchors
						diceAccessCode={diceAccessCode}
						diceLinkoutOriginPageType={DiceLinkoutOriginPageTypes.SmartLander}
						event={event}
						isOffSale={isPast}
						{...programmeLabels}
					/>
				</div>
			) : null}
		</article>
	);
};
